$primary: #46474d;
$secondary: #c4c4c4;

$small: 480px;
$medium: 775px;
$large: 1050px;

@mixin unselectable() {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
