@import './shared.scss';
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Megrim&family=Mulish&display=swap');
@import url('https://cdn.jsdelivr.net/gh/devicons/devicon@v2.12.0/devicon.min.css');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import './Background.scss';

* {
  margin: 0;
  padding: 0;
  color: $secondary;
}

#root {
  width: 100%;
  height: 100vh;
}

html,
body {
  width: 100vw;
}

body {
  position: relative;
  max-height: 100rem;
  min-width: 10vw;
  background-color: $primary;
  font-family: 'Mulish';
  overflow-x: hidden;
}

i {
  @include unselectable();
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  @include unselectable();
}

// Text Size
.text-tiny {
  font-weight: 100;
  font-size: 1rem;
}

.text-small {
  font-weight: 100;
  font-size: 1.4rem;
}

.text-medium {
  font-weight: 100;
  font-size: 1.8rem;
}

.text-large {
  font-weight: 100;
  @media screen and (max-width: 1000px) {
    font-size: 5rem;
  }
  font-size: 6rem;
}

// Center classes
.center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}
