@import './shared.scss';

@media screen and (max-width: 1500px) {
  div.background-wrapper {
    gap: 35%;
  }
}

.background-wrapper {
  position: absolute;
  z-index: -999;
  display: flex;
  justify-content: space-between;
  gap: 55%;
  width: 100%;
  height: max-content;
  overflow-x: hidden;
}

.background.img {
  width: 721px;
  height: 1247px;
  flex-shrink: 0;
}

// Shadow
.svg-left path {
  -webkit-filter: drop-shadow(30px -5px 12px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(30px -5px 12px rgba(0, 0, 0, 0.25));
}

.svg-right path {
  -webkit-filter: drop-shadow(-30px -5px 12px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(-30px -5px 12px rgba(0, 0, 0, 0.25));
  transform: translateX(55px);
}

// Positioning
.first-background {
  position: absolute;
}

.second-background {
  position: absolute;
}

.third-background {
  position: absolute;
}

@media screen and (max-width: 480px) {
  .first-background {
    top: 0rem;
  }
  .second-background {
    top: 85rem;
  }
  .third-background {
    top: 0rem;
  }
}

@media screen and (min-width: $small) {
  .first-background {
    top: -5rem;
  }
  .second-background {
    top: 105rem;
  }
  .third-background {
    top: 5rem;
  }
}

@media screen and (min-width: $medium) {
  .first-background {
    top: -5rem;
  }
  .second-background {
    top: 80rem;
  }
  .third-background {
    top: 17rem;
  }
}
