@import './shared.scss';

.contacts-section {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 37rem;
  padding-bottom: 20rem;
}

@media screen and (max-width: $small) {
  .contacts-section {
    margin-top: 14rem;
    padding-bottom: 10rem;
  }
}

@media screen and (max-width: $medium) and (min-width: $small) {
  .contacts-section {
    margin-top: 24rem;
  }
}

.contacts-scroll-ref {
  position: absolute;
  top: 30%;
}

.contacts-header {
  margin-bottom: 3rem;
}

.contacts-list {
  margin-top: 1rem;
}

.contacts-item {
  display: flex;
  list-style-type: none;
  padding-bottom: 0.7rem;
  cursor: pointer;
}

.contacts-item:hover {
  transform: scale(105%);
}

.contacts-link {
  text-decoration: none;
  display: flex;
  list-style-type: none;
  cursor: pointer;
}

.contacts-icon {
  background-color: $secondary;
  padding: 0.1rem;
  border-radius: 2rem;
  color: $primary;
  font-size: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
}

.linkedin-icon {
  padding: 0.1rem;
  height: 2.5rem;
  width: 2.5rem;
  fill: $primary;
}

.contacts-text {
  line-height: 100%;
  padding-top: 0.6rem;
  padding-left: 1rem;
}
