@import './shared.scss';

.header {
  position: fixed;
  top: 0;
  height: 2.9rem;
  width: 100%;
  z-index: 9999;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(18px);
  background-color: rgba(70, 71, 77, 0.9);
}

.header-list {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  list-style-type: none;
  @include unselectable();
  margin-left: 2rem;
  margin-right: 2rem;
}

.header-item {
  position: relative;
  cursor: pointer;
}

.header-item::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $secondary;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

@mixin headeranimation() {
  visibility: visible;
  transform: scaleX(1);
}

.header-item:hover::before {
  @include headeranimation;
}

.header-item:focus::before {
  @include headeranimation;
}

.header-item-line {
  border-right: 1px solid rgba(#c4c4c4, 0.6);
}
